import Trie from './res/trie';
import cantoneseData from './res/new.json';

export const trie = new Trie();

export const refreshTrie = (): void => {
    Object.entries(cantoneseData).forEach(([key]) => trie.insert(key));
};

refreshTrie();
