// @ts-nocheck

import React from 'react';
import ReactDOM from 'react-dom';
import Converter from './jumping/Converter';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(
  <Converter />,
  document.getElementById('root')
);
serviceWorker.register();
