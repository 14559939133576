// @ts-nocheck

import React from 'react';
import decomposeSyllable from './helpers/decomposeSyllable';
import { decodeUTF8, encodeUTF8 } from './helpers/utf8';
import { stickLeft, stickRight } from './constants';
//import { config } from 'process';

const TextImage : React.PureComponent = (props) => {
    const ONSET = 0;
    const RHYME = 1;
    const ONSET_COLOR = ['#000000', '#336699', '#CC7733', '#338844', '#442222', '#222266', '#222222'];
    const RHYME_COLOR = ['#333333', '#4499BB', '#EEAA44', '#66AA44', '#884444', '#6666AA', '#777777'];
    const COLOR = [ONSET_COLOR, RHYME_COLOR];
    const getToneColor = (context, tone, part) => {
        if (tone >= 0 && tone <= 6 && (part === ONSET || part === RHYME)) return COLOR[part][tone];
        return '#000000';
    };

    const LEVEL = 0;
    const RISING = -Math.PI / 9;
    const FALLING = Math.PI / 15;
    const ROTATE_TRANSFORM = ['', LEVEL, RISING, LEVEL, FALLING, RISING, LEVEL];
    const HEIGHT_TRANSFORM = [0.5, 0.3, 0.4, 0.5, 0.7, 0.7, 0.7];

    const GLOSS_HEIGHT_RATIO = props.config.honziHeightRatio * 0.25;
    const HONZI_HEIGHT_RATIO = props.config.honziHeightRatio * 0.50;
    const MAX_GLOSS_LANG = props.config.langOrder.split(',').length;
    const WRAP_SIZE_CURRENT = props.config.wrapSize;
    const LINE_HEIGHT_MULTIPLIER = props.config.lineHeightMultiplier;
    const HONZI_MODE = props.config.fontType;
    const GREYSCALE = props.config.greyscale;
    // const FIXED_WIDTH = props.config.fixedWidth;
    const NOPRONMARK = '#';
    const SHOW_LINE_NUM = props.config.showLineNum;
    const SHOW_LINE_BREAK = props.config.showLineBreak;
    const SHOW_SEG_LINE = props.config.showSegLine;

    const { style } = props;
    const pre = document.createElement('pre');

    const _standard = `line-height: ${style.lineHeight}; text-align: ${style.align}; padding: 0; display: block; position: fixed; top: 100%; overflow: hidden;`;
    const style0 = `${style.honziSize*(HONZI_MODE === 'Normal'? 1: 1.75)}pt "AR PL UKai HK", "Kaiti TC"`;
    // console.log(style);
    const style1 = `${style.size}pt ${style.font}`;
    const style2 = `italic ${Math.round(style.size * 0.55)}pt "FiraI"`;

    const handleConvertNew = () => {
        const canvas = document.createElement('canvas');
        if (!canvas) return;
        const context = canvas.getContext('2d');

        const strip = [];
        const wordsArr = props.words.split('\n');
        const stickyPunctuation = props.config.stickyPunctuation;
        let opening = '';
        for (let j = 0; j < wordsArr.length; ++j) {
            let [char, jp, yale, gloss] = wordsArr[j].split('|');
            let directive = '';
            if (char.match(/\(.*\)/)) {
                directive = '()';
                char = char.replace(/[()]/g, '');
            }
            if (stickyPunctuation && stickRight.includes(char)) {
                opening += char;
            } else if (stickyPunctuation && stickLeft.includes(char) && strip.length > 0) {
                strip[strip.length - 1].char += char;
            } else {
                strip.push({
                    char: (opening + char) || '', jyutping: jp || '', yale: yale || '', gloss: gloss || '', directive,
                });
                opening = '';
            }
        }

        const sideMargin = style.sideMargin * 1.0;
        const lineMargin = style.lineMargin * 1.0 * LINE_HEIGHT_MULTIPLIER;
        const wordSpacing = style.wordSpacing * 1.0;
        const { displayTones } = style;
        const { lineHeight } = style;
        const { withHonzi } = style;
        const { withGloss } = style;
        const honziSize = style.honziSize;
        const { arcMargin } = style;
        const dancing = style.mode === 'dancing';

        document.getElementById('parentDiv').setAttribute('style', 'display:flex;');
        document.getElementById('parentDiv').append(pre);

        pre.innerText = 'Naang'; // Longest possible Jyutping string, and a word with both tall and tailing letters.
        pre.setAttribute('style', _standard);
        pre.style.font = style1;

        const x = pre.offsetWidth;
        const y = pre.offsetHeight; // Line height before adjustment
        const base = y * lineHeight;
        const rowHeight = y * 3;
        const { system } = style;

        // const getFixedWidth = () => {
        //     pre.innerText = "ngaang6";
        //     pre.style.font = style1;
        //     return pre.offsetWidth;
        // }

        const getJPWidth = (jp) => {
            pre.innerText = jp;
            pre.style.font = style1;
            const syllWidth = pre.offsetWidth;
            const theta = (dancing ? ROTATE_TRANSFORM[decomposeSyllable(jp, system).tone] : 0);
            return syllWidth * Math.abs(Math.cos(theta)) + y * Math.abs(Math.sin(theta));
        };

        const getCharWidth = (char) => {
            pre.innerText = char;
            pre.style.font = style0;
            return pre.offsetWidth;
        };

        const getRequiredWidth = (word) => {
        // Width Calculation
        //
        // [   今     朝      好  # happy ]
        // [# gam1 # ziu1|# hou2|        ]
        //
        // The width of the jp line is calculated by wordSpacing * (#syll) + W
        // where W is the total width of each jyutping syllable, adjusted by orientation
        // Normally, char will be centred to the corresponding Jyutping Syllable
        // However, if char is wider than jp, then wordSpacing + W_char will be returned.
            const sylls = word[system].split('-');
            const jpWidth = sylls.reduce((a, b) => a + getJPWidth(b), 0) + wordSpacing * (sylls.length);
            const charWidth = getCharWidth(word.char) + wordSpacing;
            console.log(sylls, jpWidth, charWidth);
            return Math.max(jpWidth, charWidth);
        };

        const maxWidth = (props.config.customBreak
            ? strip.map(getRequiredWidth).reduce((a, b) => a + b) + wordSpacing : x * WRAP_SIZE_CURRENT);
        canvas.width = maxWidth + sideMargin * 2;

        const getStripNetHeight = (h, withHonzi, withGloss, dancing) => h * ((dancing ? 1 : 0.5) + (withHonzi ? HONZI_HEIGHT_RATIO : 0) + (withGloss ? MAX_GLOSS_LANG * GLOSS_HEIGHT_RATIO : 0));
        const getStripFullHeight = (h, withHonzi, withGloss, dancing) => getStripNetHeight(h, withHonzi, withGloss, dancing) + lineMargin;
        // let stripNetHeight = getStripNetHeight(rowHeight, withHonzi, withGloss);
        const stripFullHeight = getStripFullHeight(rowHeight, withHonzi, withGloss, dancing);
        canvas.height = 1 * stripFullHeight - lineMargin + (props.config.watermark ? 0.2 * rowHeight : 0);
        // Assuming there's only one line per strip first.
        context.fillStyle = style.background;
        context.clearRect(0, 0, canvas.width, canvas.height);
        context.fill();

        let stripIndex = 0;
        let stripBeginY = 0;
        let stripMainBeginY = 0;
        let stripGlossBeginY = 0;
        let stripEndY = 0;

        const increaseCanvasHeight = (lineCount) => {
            const tmpCanvas = document.createElement('canvas');
            tmpCanvas.width = canvas.width;
            tmpCanvas.height = canvas.height;
            tmpCanvas.getContext('2d').drawImage(canvas, 0, 0);
            canvas.height = (lineCount) * stripFullHeight - lineMargin + (props.config.watermark ? 0.2 * rowHeight : 0);
        canvas.getContext('2d')?.drawImage(tmpCanvas, 0, 0);
        };

        const moveToNewLine = (lineCount) => {
            stripIndex = lineCount - 1;
            stripBeginY = stripIndex * stripFullHeight;
            stripMainBeginY = stripBeginY + (withHonzi ? HONZI_HEIGHT_RATIO : 0) * rowHeight;
            stripGlossBeginY = stripMainBeginY + rowHeight * (dancing ? 1 : 0.5);
            stripEndY = stripGlossBeginY + (withGloss ? MAX_GLOSS_LANG * GLOSS_HEIGHT_RATIO * rowHeight : 0);
        };

        const drawStripBackground = (lineCount) => {
        // Draw new strip
            context.fillStyle = '#FFFFFF';
            context.beginPath();
            context.fillRect(0, Math.floor(stripMainBeginY), canvas.width - sideMargin * 2, Math.ceil(rowHeight / 2));
            context.fill();

            if (dancing) {
                context.fillStyle = '#F0F0F0';
            }
            context.beginPath();
            context.fillRect(0, Math.floor(stripMainBeginY + rowHeight / 2), canvas.width - sideMargin * 2, Math.ceil(rowHeight / 2));
            context.fill();

            if (withHonzi) {
                context.fillStyle = '#FFFFFF';
                context.beginPath();
                context.fillRect(0, Math.floor(stripBeginY), canvas.width - sideMargin * 2, Math.ceil(HONZI_HEIGHT_RATIO * rowHeight));
                context.fill();
            }

            if (withGloss) {
                context.fillStyle = '#FFFFFF';
                context.beginPath();
                context.fillRect(0, Math.floor(stripGlossBeginY), canvas.width - sideMargin * 2, Math.ceil(MAX_GLOSS_LANG * GLOSS_HEIGHT_RATIO * rowHeight));
                context.fill();
            }

            // Add watermark
            if (props.config.watermark) {
                context.font = `${honziSize * 0.4}pt FiraI`;
                context.fillStyle = '#999999';
                context.fillText('Generated by hambaanglaang.hk', 0, stripEndY + 0.15 * rowHeight);
            }

            if (SHOW_LINE_BREAK) {
                context.lineWidth = style.size / 4; // this.style.stroke;
                context.strokeStyle = '#CCAAAA';
                context.beginPath();
                context.moveTo(0, stripEndY);
                context.lineTo(canvas.width - sideMargin * 2, stripEndY);
                context.stroke();
            }
        };

        let usedWidth = 0;
        let lineCount = 0;

        strip.forEach((word, i) => {
            let endClause = false;
            const wordWidth = getRequiredWidth(word);
            console.log(wordWidth);
            if (lineCount === 0 || 
                (!props.config.customBreak && (wordWidth + usedWidth) > (maxWidth - 0.5 * wordSpacing)  && usedWidth > 0)) {
                context.restore();
                increaseCanvasHeight(++lineCount);
                context.save();
                context.translate(sideMargin, 0);
                moveToNewLine(lineCount);
                drawStripBackground(lineCount);
                usedWidth = 0;

                // Draw Word Segmentation Line for the first word
                if (SHOW_SEG_LINE) {
                    context.lineWidth = style.size / 70; // this.style.stroke;
                    context.strokeStyle = '#AAAAAA';
                    context.beginPath();
                    context.moveTo(usedWidth + 0.5 * wordSpacing, stripBeginY);
                    context.lineTo(usedWidth + 0.5 * wordSpacing, stripEndY);
                    context.stroke();
                }

                if (SHOW_LINE_NUM) {
                    context.font = style1;
                    context.fillStyle = '#333333';
                    context.fillText(lineCount, 0, stripBeginY + wordSpacing);
                }
            }

            const sylls = word[system].split('-');
            let chars = decodeUTF8(encodeUTF8(word.char));

            while (stickRight.includes(chars[0]) && chars.length > 1) {
                chars = [chars[0] + chars[1], ...chars.slice(2)];
            }
            if (chars.length > sylls.length) {
                chars[sylls.length - 1] = chars.slice(sylls.length - 1).join('');
            }

            // Draw Gloss (word level)
            if (withGloss) {
                if (word.gloss) {
                // Add multilingual support
                // Different langugaes delimited by #
                    const glosses = word.gloss.split('#');
                    context.font = style2;
                    context.fillStyle = '#333333';
                    pre.style.font = context.font;

                    for (let g = 0, prevHeightSum = 0; g < glosses.length; ++g) {
                        pre.innerText = glosses[g];
                        const posX = usedWidth + (wordWidth - pre.offsetWidth + wordSpacing) / 2;
                        const posY = stripGlossBeginY + 0.3 * base + prevHeightSum;
                        prevHeightSum += Math.max(rowHeight * GLOSS_HEIGHT_RATIO, pre.offsetHeight);
                        context.fillText(glosses[g], posX, posY);
                    }
                }
            }

            // Draw the components (Jyutping, Honzi) by syllable

            for (let i = 0, prev = null, properNamePoint = null; i < sylls.length; ++i) {
                const syll = decomposeSyllable(sylls[i], system);
                const toneRow = (dancing ? HEIGHT_TRANSFORM[syll.tone] : 0.3);
                const contour = (dancing ? ROTATE_TRANSFORM[syll.tone] : 0);
                const charFullWidth = (withHonzi ? getCharWidth(chars[i]) : 0);
                const syllWidth = getJPWidth(syll.syll);
                const onsetWidth = getJPWidth(syll.syll) - getJPWidth(syll.syll.substr(syll.onset.length));

                const normalX = usedWidth + wordSpacing;
                const normalY = toneRow * rowHeight + stripMainBeginY;

                // Adjust normalX for rising tones

                // Draw Jyutping / Yale

                context.font = `${style1}`;
                context.textAlign = style.align;
                context.lineWidth = style.stroke;
                context.strokeStyle = style.strokeColor;
                context.fillStyle = style.color;
                context.save();

                let horizontalAdjust = (dancing && ROTATE_TRANSFORM[syll.tone] < 0
                    ? Math.abs(y * Math.sin(ROTATE_TRANSFORM[syll.tone])) : 0);
                if (charFullWidth > syllWidth + horizontalAdjust) {
                    horizontalAdjust += (charFullWidth - syllWidth - horizontalAdjust) / 2;
                }
                context.translate(normalX + horizontalAdjust, normalY);
                context.rotate(contour);
                context.textAlign = 'left';

                const gradient = context.createLinearGradient(0, 0, syllWidth, 0);
                const o2s_ratio = onsetWidth / syllWidth;
                const color_onset = getToneColor(context, GREYSCALE ? 0 : syll.tone, ONSET);
                const color_rhyme = getToneColor(context, GREYSCALE ? 0 : syll.tone, RHYME);

                gradient.addColorStop(0, color_onset);
                if (o2s_ratio >= 0 && o2s_ratio <= 1) {
                    gradient.addColorStop(o2s_ratio, color_onset);
                    gradient.addColorStop(o2s_ratio, color_rhyme);
                }
                gradient.addColorStop(1, color_rhyme);
                context.fillStyle = gradient;

                const word_for_display = (displayTones ? syll.syll : syll.notone).replace(NOPRONMARK, '');

                if (style.stroke) {
                    context.strokeText(word_for_display, 0, 0);
                }
                context.fillText(word_for_display, 0, 0);
                context.restore();

                if (prev) {
                // Draw Connector Lines
                    context.lineWidth = style.size / 50; // this.style.stroke;
                    context.strokeStyle = 'red';

                    const twopoints = {
                        start: prev,
                        end: { x: normalX + horizontalAdjust, y: normalY + y * 0.1 },
                        end_high: { x: normalX + horizontalAdjust, y: normalY - y * 0.5 },
                        heightAdjustment: toneRow,
                    };

                    // STRAIGHT LINE
                    // context.beginPath();
                    // context.moveTo(twopoints.start.x, twopoints.start.y);
                    // context.lineTo(twopoints.end.x, twopoints.end.y);
                    // context.stroke();

                    // ARC
                    if (dancing) {
                        let RADIUS = rowHeight / 3;

                        const highConnect = twopoints.heightAdjustment > 0.45;
    
                        if (highConnect) { twopoints.end = twopoints.end_high; }
    
                        const pointDist = Math.sqrt((twopoints.end.y - twopoints.start.y) * (twopoints.end.y - twopoints.start.y)
                                            + (twopoints.end.x - twopoints.start.x) * (twopoints.end.x - twopoints.start.x));
    
                        let arc_size = Math.acos((2 * RADIUS * RADIUS - pointDist * pointDist) / (2 * RADIUS * RADIUS)); // Cosine Law
                        while (!arc_size) {
                            RADIUS *= 1.1;
                            arc_size = Math.acos((2 * RADIUS * RADIUS - pointDist * pointDist) / (2 * RADIUS * RADIUS));
                        }
    
                        const midPoint = {
                            x: (twopoints.start.x + twopoints.end.x) / 2,
                            y: (twopoints.start.y + twopoints.end.y) / 2,
                        };
    
                        const rotatePoint = {
                            x: -(twopoints.end.y - twopoints.start.y),
                            y: (twopoints.end.x - twopoints.start.x),
                        };
                        const dist = Math.sqrt(rotatePoint.x * rotatePoint.x + rotatePoint.y * rotatePoint.y);
    
                        const center1 = {
                            x: midPoint.x + rotatePoint.x * Math.sqrt(Math.abs((RADIUS * RADIUS) / (dist * dist) - 1 / 4)),
                            y: midPoint.y + rotatePoint.y * Math.sqrt(Math.abs((RADIUS * RADIUS) / (dist * dist) - 1 / 4)),
                        };
    
                        const center2 = {
                            x: midPoint.x - rotatePoint.x * Math.sqrt((RADIUS * RADIUS) / (dist * dist) - 1 / 4),
                            y: midPoint.y - rotatePoint.y * Math.sqrt((RADIUS * RADIUS) / (dist * dist) - 1 / 4),
                        };
    
                        // Debug: Showing the Centre of the Arc
                        // context.strokeStyle = 'blue';
                        // context.beginPath();
                        // context.arc(center1.x, center1.y, 2, 0, 2*Math.PI);
                        // context.stroke();
    
                        // context.strokeStyle = 'green';
                        // context.beginPath();
                        // context.arc(center2.x, center2.y, 2, 0, 2*Math.PI);
                        // context.stroke();
    
                        const start_angle1 = Math.atan((twopoints.start.y - center1.y) / (twopoints.start.x - center1.x))
                                     - (twopoints.start.x < center1.x ? Math.PI : 0);
    
                        const start_angle2 = Math.atan((twopoints.end.y - center2.y) / (twopoints.end.x - center2.x))
                                     - (twopoints.end.x < center2.x ? Math.PI : 0);
    
                        if (highConnect) {
                            context.beginPath();
                            context.arc(center1.x, center1.y, RADIUS, start_angle1 + arcMargin, start_angle1 + arc_size - arcMargin);
                            context.stroke();
                        } else {
                            context.beginPath();
                            context.arc(center2.x, center2.y, RADIUS, start_angle2 + arcMargin, start_angle2 + arc_size - arcMargin);
                            context.stroke();
                        }
                    }
                }

                prev = { x: normalX + syllWidth * Math.cos(contour), y: normalY + y * Math.sin(contour) };

                // Drawing Honzi
                if (withHonzi) {
                    if (chars[i]) {
                        context.font = style0;
                        const stickLeftString = chars[i].match(new RegExp(`^[${stickRight.join('')}]*`));
                        const stickRightString = chars[i].match(new RegExp(`[${stickLeft.join('')}]*$`));

                        const stickLeftWidth = getCharWidth(stickLeftString);
                        const stickRightWidth = getCharWidth(stickRightString);
                        if (stickRightWidth > 0) endClause = true;

                        const posX = normalX + (charFullWidth > syllWidth + horizontalAdjust ? 0 : (syllWidth - charFullWidth) / 2);
                        const posY = stripMainBeginY - 0.3 * base;

                        context.fillStyle = '#333333';
                        context.fillText(chars[i], posX, posY);

                        // Handle proper names
                        if (word.directive === '()') {
                            if (i === 0) {
                                properNamePoint = {
                                    x: posX + stickLeftWidth,
                                    y: stripMainBeginY - 0.2 * base,
                                };
                            }
                            if (i === sylls.length - 1) {
                                if (properNamePoint) {
                                    context.lineWidth = style.size / 15;
                                    context.strokeStyle = 'black';
                                    const twopoints = {
                                        start: properNamePoint,
                                        end: { x: posX + charFullWidth - stickRightWidth, y: properNamePoint.y },
                                    };
                                    context.beginPath();
                                    context.moveTo(twopoints.start.x, twopoints.start.y);
                                    context.lineTo(twopoints.end.x, twopoints.end.y);
                                    context.stroke();
                                }
                            }
                        }
                    }
                }
                usedWidth += Math.max(syllWidth, charFullWidth) + wordSpacing;
            }
            // Draw Word Segmentation Line
            if (SHOW_SEG_LINE) {
                context.lineWidth = style.size / 70 * (endClause? 2 : 1); // this.style.stroke;
                context.strokeStyle = '#AAAAAA';
                context.beginPath();
                context.moveTo(usedWidth + 0.5 * wordSpacing, stripBeginY);
                context.lineTo(usedWidth + 0.5 * wordSpacing, stripEndY);
                if (endClause){
                    context.moveTo(usedWidth + 0.6 * wordSpacing, stripBeginY);
                    context.lineTo(usedWidth + 0.6 * wordSpacing, stripEndY);
                }
                context.stroke();
            }
        });

        document.getElementById('parentDiv').removeChild(pre);
        context.restore();
        return canvas;
    };

    const latest_canvas = handleConvertNew();

    const toImage = () => {
        if (latest_canvas) {
            return latest_canvas.toDataURL();
        }

        return '';
    };

    return (<img id={props.id} name={props.name} className='jumping-images' src= {toImage()} alt={props.message}/>);
};

export default TextImage;
