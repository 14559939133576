import replaceAll from './replaceAll';
import { jp2yale, yale2jp } from '../constants';

const convertSystem = (text: string, system: string): string => {
    text += ' ';
    if (system === 'yale') {
        for (const i in jp2yale) {
            text = replaceAll(text, jp2yale[i].from, jp2yale[i].to);
        }
        text = text.normalize('NFC');
    }

    if (system === 'jyutping') {
        text = text.normalize('NFD');
        for (const i in yale2jp) {
            text = replaceAll(text, yale2jp[i].from, yale2jp[i].to);
        }
    }
    return text.substr(0, text.length - 1);
};

export default convertSystem;
