import { jyutpingFinals, jyutpingInitials } from '../constants';

// TODO Don't allow decomposing more than one syllable?
const decomposeJyutping = (syllables: string): Record<string, unknown> => {
    const allSylls = syllables.split(';') || [];
    const syllable = allSylls[0];

    const jyutping = `^(${jyutpingInitials.join('|')})(${jyutpingFinals.join('|')})([1-6])$`;
    const matchJyutping = (new RegExp(jyutping, 'i')).exec(syllable);

    if (matchJyutping) {
        return {
            type: 'jyutping',
            onset: matchJyutping[1],
            rhyme: matchJyutping[2],
            tone: matchJyutping[3],
            syll: syllable,
            notone: matchJyutping[1] + matchJyutping[2],
        };
    }
    return {
        onset: '',
        rhyme: syllable,
        tone: 0,
        syll: syllable,
        notone: syllable,
        type: 'invalid',
        ambiguous: allSylls.length > 1,
    };
};

export default decomposeJyutping;
