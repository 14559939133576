// @ts-nocheck

import React, { useState } from 'react';
import JSZip from 'jszip';
import FileSaver from 'file-saver';

import {
    Theme, createStyles, makeStyles, withStyles,
} from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import InputBase from '@material-ui/core/InputBase';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Switch from '@material-ui/core/Switch';
import { grey, blueGrey, blue, green } from '@material-ui/core/colors';
import Typography from '@material-ui/core/Typography';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';

import FormGroup from '@material-ui/core/FormGroup';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import {
    Tab, TabList, TabPanel, Tabs,
} from 'react-tabs';
import cantoneseData from './res/new.json';

import 'react-tabs/style/react-tabs.css';

import TextImageWrapper from './TextImageWrapper';

import { stickLeft, stickRight } from './constants';
import markSyllableBoundaries from './helpers/markSyllableBoundaries';
import convertSystem from './helpers/convertSystem';
import { countChar, decodeUTF8, encodeUTF8 } from './helpers/utf8';
import { refreshTrie } from './cantoneseTrie';
import segment from './helpers/segment';
import './css/style.css';

interface PanelProps
{
    children: any,
    defaultText: string,
    onTextChange: Function,
    onRequestGenerate: Function,
    value: any
}

const Panel1: React.PureComponent<PanelProps> = (props) => {
    const handleTextUpdated = (e:any) => {
        const text = e.target.value;
        props.onTextChange(text);
    };

    const handleSegment = (e:any) => {
        const text = segment(props.text).trim();
        props.onTextChange(text);
    };

    const handleGenerate = (e:any) => { props.onRequestGenerate(); };

    const handleImport = (e:any) => {
        let reader = new FileReader();
        reader.onload = (e:any) => {
            let contents = e.target.result;
            let lines = contents.split('\n');
            let text = '';
            while (lines.length >=4) {
                let [label, l0, l1, l3] = lines.slice(0,4);
                lines = lines.slice(4);
                l0 = l0.replaceAll('\t<','').replaceAll('|','\t');
                l1 = l1.replaceAll('\t<','-').replaceAll('|','\t');
                l3 = l3.replaceAll('\t<',' ').replaceAll('|','\t');

                let l0A = l0.split('\t');
                let l1A = l1.split('\t');
                let l3A = l3.split('\t');
                text += label.trim() + l0A.map( (word,i) => word.trim()?`<<${word.trim()}|${l1A[i].trim()}|${convertSystem(l1A[i].trim(), 'yale')}|${l3A[i].trim()}>>`:'').join('') + '\n';
            }
            props.onTextChange(text);
          };
        reader.readAsText(e.target.files[0]);
    };

    return (
        <Grid container direction="column" spacing={1}>
            <Grid item>
                <TextField
                    id="primary-inbox-box"
                    label="漢字 | Characters"
                    multiline
                    rows={10}
                    fullWidth
                    value={props.text}
                    variant="outlined"
                    onChange={handleTextUpdated}
                />
            </Grid>
            <Grid item container direction="row" spacing={1}>
                <Grid item>
                    <input type="file" name="file" onChange={handleImport}/>
                </Grid>
                <Grid item>
                    <Button variant="contained" color="primary" onClick={handleSegment}>
                        Segment 分詞
                    </Button>
                </Grid>
                <Grid item>
                    <Button variant="contained" color="secondary" onClick={handleGenerate}>
                        Generate 生成
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    );
};

const StripPanel: React.PureComponent<PanelProps> = (props) => {
    const [wordTableLine, setWordTableLine] = useState(props.wordTableLine);

    const wordTableLine2derivedText = (wordComposition, i) => {
        const line = wordComposition.map((word) => word[word[0]].char).join(' ').replace(/([A-Za-z0-9]) (?=[A-Za-z0-9])/g, '$1__').replace(/ /g, '');
        let lineJp;
        if (props.config.system === 'jyutping') {
            lineJp = wordComposition.map((word) => word[word[0]].jp).join(' ').trim();
        } else {
            lineJp = wordComposition.map((word) => convertSystem(word[word[0]].jp, 'yale')).join(' ').trim();
        }

        const lineTran = wordComposition.map(
            (word) => (word[word[0]].trans
        || (props.config.langOrder.split(',')
            .map((lang) => word[word[0]][lang])
            .join('#')
        ) || '#'
            ) + '|'.repeat(countChar(word[word[0]].char)),
        ).join('');
        return `${line.replace(/ /g, '')}\n${lineJp}\n${lineTran}`;
    };

    const wordTableLine2segLine = (wordComposition, i) => wordComposition.map((word) => [
        word[word[0]].char,
        word[word[0]].jp,
        convertSystem(word[word[0]].jp, 'yale'),
        (word[word[0]].trans
          || (props.config.langOrder.split(',')
              .map((lang) => word[word[0]][lang])
              .join('#')
          ) || '#'
        ),
    ].join('|')).join('\n');

    const handleChunkOptionSelect = (chunk:number, opt:number) => {
        // console.log('handleChunkOptionSelect');
        const updated = [].concat(wordTableLine);
        updated[chunk][0] = opt;
        setWordTableLine(updated);
        // regenerate derived text
        setDerivedTextLocal(wordTableLine2derivedText(wordTableLine));
        setSegLine(wordTableLine2segLine(wordTableLine));
    };

    const handleWordTableChange = (chunk:number, opt:number, lang:string, newTran:string) => {
        // console.log('handleWordTableChange');
        const updated = [].concat(wordTableLine);
        updated[chunk][opt][lang] = newTran;
        setWordTableLine(updated);
        setDerivedTextLocal(wordTableLine2derivedText(wordTableLine));
        setSegLine(wordTableLine2segLine(wordTableLine));
    };

    const lineName = props.config.directives[props.ln].name || (`Line ${props.ln}`);

    const [derivedTextLocal, setDerivedTextLocal] = useState(wordTableLine2derivedText(wordTableLine));
    const [segLine, setSegLine] = useState(wordTableLine2segLine(wordTableLine));

    return (
        <Grid container spacing={1}>
            <Grid item xs={12} sm={6}>
                <Panel3
                    ln={props.ln}
                    name={lineName}
                    text={derivedTextLocal}
                    text2={segLine}
                    config={props.config}
                    onUpdateMessage={props.onUpdateMessage}
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <Panel2
                    ln={props.ln}
                    name={lineName}
                    text={derivedTextLocal}
                    text2={segLine}
                    langOrder={props.config.langOrder}
                    wordTableLine={wordTableLine}
                    onTextChange={setDerivedTextLocal}
                    onText2Change={setSegLine}
                    onChunkOptionSelect={handleChunkOptionSelect}
                    onWordTableChange={handleWordTableChange}
                />
            </Grid>
        </Grid>
    );
};

const StripPanels: React.PureComponent<PanelProps> = (props) => {
    const items = [];
    for (const [index, wordTableLine] of props.wordTable.entries()) {
        items.push(<StripPanel
            ln={index}
            key={`strip${index}`}
            config={props.config}
            wordTableLine={wordTableLine}
            onUpdateMessage={props.onUpdateMessage}
        />);
    }
    const downloadAll = () => {
        const images = document.querySelectorAll('.jumping-images');
        const zip = new JSZip();
        for (const i of images) {
            zip.file(`${props.config.filePrefix} ${i.name.toLowerCase().trim()}.png`, i.src.split(',')[1], { binary: true, base64: true });
        }
        zip.generateAsync({ type: 'blob' })
            .then((content) => {
                // see FileSaver.js
                FileSaver.saveAs(content, `${props.config.filePrefix}.zip`);
            });
    };

    const exportCode = () => {
        const save = [...document.getElementsByTagName('textarea')].map((element) => {
            const label = element.name.trim();
            const lines = element.value.split('\n').map((line) => `<<${line}>>`);
            // let s = '';
            // for (let j = 0; j < lines.length / 3; ++j) {
            //   s += "[" + label + (j > 0? j: "") + "] " + lines[j*3] + "{{" + lines[j*3+1] + "}}[[" + lines[j*3+2] + "]]" + (j + 1 < lines.length / 3? "\n": "");
            // }
            return `[${label}]${lines.join('')}`;
        }).join('\n');
        const blob = new Blob([save], {
            type: 'text/plain;charset=utf-8',
        });
        saveAs(blob, `${props.config.filePrefix}.txt`);
    };

    const exportTSV = () => {
        const save = [...document.getElementsByTagName('textarea')].map((element) => {
            const label = element.name.trim();
            const line0 = element.value.split('\n').map((line) => line.split('|')[0]);
            const line1 = element.value.split('\n').map((line) => line.split('|')[1]);
            // const line2 = element.value.split('\n').map((line) => line.split('|')[2]); // Skipping Yale 
            const line3 = element.value.split('\n').map((line) => line.split('|')[3]);
            return `[${label}]\n${line0.join('\t')}\n${line1.join('\t')}\n${line3.join('\t')}`;
        }).join('\n');
        const blob = new Blob([save], {
            type: 'text/plain;charset=utf-8',
        });
        saveAs(blob, `${props.config.filePrefix}.tsv`);
    };
    return (
        <div>
            {items}
            <hr />
            <div style={{ textAlign: 'center' }}>
                <Button variant="contained" color="primary" onClick={downloadAll}>下載 Download</Button>
                <Button variant="contained" color="secondary" onClick={exportCode}>匯出代碼 Export Code</Button>
                <Button variant="contained" color="secondary" onClick={exportTSV}>匯出TSV Export TSV</Button>
            </div>
        </div>
    );
};

const OptionEntry: React.PureComponent = (props) => {
    const [state, setState] = React.useState({ ...props });

    const handleTransChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const updated = [].concat(state.trans);
        const langOrder = event.target.name;
        const lang = props.langs[langOrder];
        updated[langOrder] = event.target.value;
        const latestState = { ...state, trans: updated };
        setState(latestState);
        props.onWordTableChange(props.wordIndex, props.opt, lang, event.target.value);
    };

    const handleJyutpingChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const latestState = { ...state, jp: event.target.value };
        setState(latestState);
        props.onWordTableChange(props.wordIndex, props.opt, 'jp', event.target.value);
    };

    if (!props.opt) return (null);
    return (
        <Grid
            container spacing={1}
            id={`${props.ln}-${props.wordIndex}-${props.opt}`}
            style={{ opacity: props.opacity }}
        >
            <Grid item xs={1}>
                {props.optionCount > 1
          && <Radio
              value={props.opt}
              size="small"
              style={{ width: '0.2em', height: '0.2em' }}
          />
                }
            </Grid>
            <Grid item xs={3}>
                <span style={{ fontWeight: 800, color: 'navy' }}>{props.char}</span><br />
                <InputBase
                    name='jp'
                    label='Jyutping'
                    style={{ fontFamily: 'jyutping', verticalAlign: 'bottom' }}
                    value={props.jp}
                    onChange={handleJyutpingChange}
                />
            </Grid>
            {!props.customTrans
          && <Grid container item xs={8}>
              {props.langs.map((lang, i) => i < 3 // Only show the first three languages
          && <Grid
              key={`g-${props.ln}-${props.wordIndex}-${props.opt}`}
              item xs={ (props.langs.length === 1) ? 12 : 6}
              style={{ verticalAlign: 'bottom' }}>
              <TextField
                  name={`${i}`}
                  label={lang}
                  InputProps={{ style: { fontFamily: 'FiraI' } }}
                  value={props.trans[i]}
                  size="small"
                  onChange={handleTransChange}
                  InputLabelProps={{
                      shrink: true,
                  }}
                  fullWidth
              />
          </Grid>)}
          </Grid>
            }
            {props.customTrans
      && <Grid item xs={8} style={{ verticalAlign: 'bottom' }}>
          <TextField
              InputProps={{ style: { fontFamily: 'FiraI' } }}
              value={props.customTrans}
              size="small"
          />
      </Grid>
            }
        </Grid>
    );
};

const ChunkEntry: React.PureComponent = (props) => {
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        props.onChunkOptionSelect(props.wordIndex, event.target.value * 1);
    };

    return (!stickLeft.concat(stickRight).includes(props.chunk[props.chunk[0]].char)
    && <div style={{ borderBottomStyle: 'dashed', borderColor: 'grey', borderWidth: '0.2px' }}>
        <RadioGroup value={props.chunk[0]} onChange={handleChange}>
            {props.chunk.map(
                (option, opt) => (opt > 0
                    ? <OptionEntry
                        key={['opt', props.ln, opt].join('-')}
                        ln={props.ln}
                        wordIndex={props.wordIndex}
                        opt={opt}
                        selected={props.chunk[0]}
                        optionCount={props.chunk.length - 1}
                        char={option.char}
                        jp={option.jp}
                        customTrans={option.trans || ''}
                        trans={props.langs.map((lang) => option[lang] || '')}
                        langs={props.langs}
                        opacity={1 - (1 - (props.chunk[0] === opt)) * 0.5}
                        onWordTableChange={props.onWordTableChange}
                    />
                    : null),
            )}
        </RadioGroup>
    </div>
    );
};

const WordTable: React.PureComponent<PanelProps> = (props) => (
    <ul
        key={`word-table-${props.ln}`}
    >
        {props.line.map(
            (chunk, wordIndex) => <ChunkEntry
                key={`${props.ln}-${wordIndex}`}
                chunk={chunk}
                ln={props.ln}
                wordIndex={wordIndex}
                langs={props.langs}
                onChunkOptionSelect={props.onChunkOptionSelect}
                onWordTableChange={props.onWordTableChange}
            />,
        )}
    </ul>
);

const Panel2: React.PureComponent<PanelProps> = (props) => {

    const [showPanel, setShowPanel] = useState(false);

    const handleText2Updated = (e:any) => {
        const text = e.target.value;
        props.onText2Change(text);
    };

    const handleShowPanelChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setShowPanel(event.target.type === 'checkbox' ? event.target.checked : event.target.value);
    };

    const ColoredSwitch = withStyles({
        switchBase: {
            color: grey[200],
            '&$checked': {
                color: blueGrey[500],
            },
            '&$checked + $track': {
                backgroundColor: blueGrey[200],
            },
        },
        checked: {},
        track: {},
    })(Switch);

    return (
        <div>
            <Box display="flex" flexDirection="row" alignItems="center" marginLeft={1}>
                <span>View 檢視</span>
                <ColoredSwitch checked={showPanel} onChange={handleShowPanelChange} name="showPanel" />
                <span>Edit 編輯</span>
            </Box>
            {(!showPanel) && 
                    <TextField
                    label={`${props.name}`}
                    name={`${props.name}`}
                    multiline
                    rows={props.text2.split('\n').length}
                    fullWidth
                    variant="standard"
                    value={props.text2}
                />
            }
            {(showPanel) && <Tabs forceRenderTabPanel>
                <TabList>
                    <Tab><Typography component={'span'}>表 Table</Typography></Tab>
                    <Tab><Typography component={'span'}>進階 Advanced</Typography></Tab>
                </TabList>
                <TabPanel>
                    <WordTable
                        ln={props.ln}
                        line={props.wordTableLine}
                        langs={props.langOrder.split(',')}
                        onChunkOptionSelect={props.onChunkOptionSelect}
                        onWordTableChange={props.onWordTableChange}
                    />
                </TabPanel>
                <TabPanel>
                    <TextField
                        label={`${props.name}`}
                        name={`${props.name}`}
                        multiline
                        rows={props.text2.split('\n').length}
                        fullWidth
                        variant="outlined"
                        value={props.text2}
                        onChange={handleText2Updated}
                    />
                </TabPanel>
            </Tabs>}
        </div>
    );
};

const Panel3: React.PureComponent<PanelProps> = (props) => {
    const FONTS = { jyutping: 'Jyutping', yale: 'Yale' };

    const style = {
        align: 'left',
        arcMargin: 0.1,
        background: 'rgba(0, 0, 0, 0)',
        bold: false,
        canvasMargin: 10,
        color: '#000000',
        displayTones: true,
        // fixedWidth: true,
        font: FONTS[props.config.system],
        greyscale: false,
        honziSize: props.config.fontSize * 0.8,
        italic: false,
        lineHeight: 1.2,
        lineMargin: 10,
        mode: props.config.mode,
        sideMargin: props.config.fontSize,
        size: props.config.fontSize,
        stroke: 1,
        strokeColor: '#FFFFFF',
        system: props.config.system,
        withGloss: true,
        withHonzi: true,
        stickyPunctuation: true,
        wordSpacing: props.config.fontSize * 0.8,
        fontType: 'Normal',
        wrapType: 'A5', // Can be A4, A5, or nowrap
    };
    return (
        <div className='image-display'>
            <Typography component="div"><h3>{props.name}</h3></Typography>
            <TextImageWrapper
                id={props.ln}
                name={props.name}
                config={props.config}
                message={props.text}
                words={props.text2}
                onUpdateMessage={props.onUpdateMessage}
                style={style}
            />
        </div>
    );
};

const ConfigPanel: React.PureComponent<PanelProps> = (props) => {
    const [state, setState] = React.useState({ ...props.config });

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
        const latestState = { ...state, [event.target.name]: value };
        setState(latestState);
        props.onConfigChange(latestState);
    };

    const ColoredSwitch = withStyles({
        switchBase: {
            color: blue[300],
            '&$checked': {
                color: green[500],
            },
            '&$checked + $track': {
                backgroundColor: green[200],
            },
        },
        checked: {},
        track: {},
    })(Switch);

    return (
        <FormControl component="fieldset">
            <Typography component="div">
                <Grid container direction="column" spacing={2}>
                    <Grid item>
                        <FormGroup>
                            <FormLabel component="legend">拼音系統 Romanisation System</FormLabel>
                            <Box display="flex" flexDirection="row" alignItems="center" marginLeft={1}>
                                <span>耶魯 Yale</span>
                                <ColoredSwitch checked={state.useJyutping} onChange={handleChange} name="useJyutping" />
                                <span>粵拼 Jyutping</span>
                            </Box>
                        </FormGroup>
                    </Grid>
                    <Grid item>
                        <FormGroup row>
                            <FormLabel component="legend">斷行設定 Line Wrapping</FormLabel>
                            <Grid container>
                                {(state.isManual) && (
                                    <Grid component="label" container item alignItems="center" spacing={1}>
                                        <Grid item style={{ marginRight: '3em' }} xs={3}>
                                            <FormControlLabel
                                                value="isManual"
                                                control={<Checkbox color="primary" onChange={handleChange} name="isManual" checked={state.isManual}/>}
                                                label="人手 Manual"
                                                labelPlacement="start"
                                            />
                                        </Grid>
                                        <Grid container item xs={3}>
                                            <TextField
                                                id="wrap-size"
                                                name='wrapSize'
                                                label="每行音節 Syll-per-line"
                                                type="number"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                value={state.wrapSize}
                                                onChange={handleChange}
                                            />
                                        </Grid>
                                    </Grid>)}
                                {(!state.isManual) && (
                                    <Grid component="label" container item alignItems="center" spacing={1}>
                                        <Grid item style={{ marginRight: '3em' }} xs={3}>
                                            <FormControlLabel
                                                value="isManual"
                                                control={<Checkbox color="primary" onChange={handleChange} name="isManual" checked={state.isManual}/>}
                                                label="人手 Manual"
                                                labelPlacement="start"
                                            />
                                        </Grid>
                                        <Grid container item xs={3}>
                                            <span style={{ display: (state.isManual ? 'none' : 'flex'), alignItems: 'center' }}>
                                                <span>A5</span>
                                                <ColoredSwitch checked={state.wrapA4} onChange={handleChange} name="wrapA4" />
                                                <span>A4</span>
                                            </span>
                                        </Grid>
                                    </Grid>)}
                            </Grid>
                        </FormGroup>
                    </Grid>
                    <Grid item>
                        <FormGroup>
                            <FormLabel component="legend">顯示設定 Display Settings</FormLabel>
                            <Box display="flex" flexDirection="row" alignItems="center" marginLeft={1}>
                                <span>冷靜 Calm</span>
                                <ColoredSwitch checked={state.dancing} onChange={handleChange} name="dancing"/>
                                <span>跳跳紮 Dancing</span>
                            </Box>
                            <Box display="flex" flexDirection="row" alignItems="center" marginLeft={1}>
                                <span>正常漢字 Normal Honzi</span>
                                <ColoredSwitch checked={state.isBigFont} onChange={handleChange} name="isBigFont"/>
                                <span>加大漢字 Big Honzi</span>
                            </Box>
                            <Box display="flex" flexDirection="row" alignItems="center" marginLeft={1}>
                                <span>彩色 Colour-coded</span>
                                <ColoredSwitch checked={state.greyscale} onChange={handleChange} name="greyscale"/>
                                <span>黑白 Black and White</span>
                            </Box>
                            <Box display="flex" flexDirection="row" alignItems="center" marginLeft={1}>
                                <span>分拆符號 Separate punctuation</span>
                                <ColoredSwitch checked={state.stickyPunctuation} onChange={handleChange} name="stickyPunctuation"/>
                                <span>黏着符號 Sticky punctuation</span>
                            </Box>
                            {/* <Box display="flex" flexDirection="row" alignItems="center" marginLeft={1}>
                                <span>浮動字寬 Variable Width</span>
                                <ColoredSwitch checked={state.fixedWidth} onChange={handleChange} name="fixedWidth"/>
                                <span>固定字寬 Fixed Width</span>
                            </Box> */}
                            
                        </FormGroup>
                    </Grid>
                    <FormGroup row>
                        <Grid item style={{ marginRight: '3em' }} xs={3}>
                            <FormControlLabel
                                value="showLineNum"
                                control={<Checkbox color="primary" onChange={handleChange} name="showLineNum" checked={state.showLineNum}/>}
                                label="行號 Line Num."
                                labelPlacement="start"
                            />
                        </Grid>
                        <Grid item style={{ marginRight: '3em' }} xs={3}>
                            <FormControlLabel
                                value="showLineBreak"
                                control={<Checkbox color="primary" onChange={handleChange} name="showLineBreak" checked={state.showLineBreak}/>}
                                label="行線 Line Break"
                                labelPlacement="start"
                            />
                        </Grid>
                        <Grid item style={{ marginRight: '3em' }} xs={3}>
                            <FormControlLabel
                                value="showSegLine"
                                control={<Checkbox color="primary" onChange={handleChange} name="showSegLine" checked={state.showSegLine}/>}
                                label="分詞線 Word Seg. Line"
                                labelPlacement="start"
                            />
                        </Grid>
                        <Grid item style={{ marginLeft: '3em', marginRight: '3em' }} xs={3}>
                            <TextField
                                id="line-height-multiplier"
                                name='lineHeightMultiplier'
                                label="行距倍數 Line Height Multi."
                                type="number"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={state.lineHeightMultiplier}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item style={{ marginLeft: '3em', marginRight: '3em' }} xs={3}>
                            <TextField
                                id="font-size"
                                name='fontSize'
                                label="字體大小 Font Size"
                                type="number"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={state.fontSize}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item style={{ marginLeft: '3em', marginRight: '3em' }} xs={3}>
                            <TextField
                                id="honzi-height-ratio"
                                name='honziHeightRatio'
                                label="漢字行高 Honzi Line Height"
                                type="number"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={state.honziHeightRatio}
                                onChange={handleChange}
                            />
                        </Grid>
                        {/* <Grid item style={{ marginLeft: '3em', marginRight: '3em' }} xs={3}>
                            <TextField
                                id="gloss-height-ratio"
                                name='glossHeightRatio'
                                label="翻譯行高 Gloss Line Height"
                                type="number"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={state.glossHeightRatio}
                                onChange={handleChange}
                            />
                        </Grid> */}
                    </FormGroup>
                    <Grid item>
                        <FormGroup>
                            <FormLabel component="legend">語言 Languages</FormLabel>
                            <Box display="flex" flexDirection="row" alignItems="center" m={1}>
                                <FormControlLabel
                                    value="eng"
                                    control={<Checkbox color="primary" onChange={handleChange} checked={state.eng} name="eng"/>}
                                    label="English"
                                    labelPlacement="top"
                                />
                                <FormControlLabel
                                    value="cmn"
                                    control={<Checkbox color="primary" onChange={handleChange} checked={state.cmn} name="cmn"/>}
                                    label="简体中文"
                                    labelPlacement="top"
                                />
                                <FormControlLabel
                                    value="urd"
                                    control={<Checkbox color="primary" onChange={handleChange} checked={state.urd} name="urd"/>}
                                    label="Urdu"
                                    labelPlacement="top"
                                />
                                <FormControlLabel
                                    value="nep"
                                    control={<Checkbox color="primary" onChange={handleChange} checked={state.nep} name="nep"/>}
                                    label="Nepali"
                                    labelPlacement="top"
                                />
                                <FormControlLabel
                                    value="hin"
                                    control={<Checkbox color="primary" onChange={handleChange} checked={state.hin} name="hin"/>}
                                    label="Hindi"
                                    labelPlacement="top"
                                />
                                <FormControlLabel
                                    value="ind"
                                    control={<Checkbox color="primary" onChange={handleChange} checked={state.ind} name="ind"/>}
                                    label="Indonesian"
                                    labelPlacement="top"
                                />
                            </Box>
                            <TextField
                                id="langOrder"
                                name='langOrder'
                                label="語言設定 Language Configuration"
                                type="text"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={state.langOrder}
                                onChange={handleChange}
                                style={{ display: 'None' }}
                            />
                        </FormGroup>
                    </Grid>
                    <Grid item>
                        <TextField
                            id="filePrefix"
                            name='filePrefix'
                            label="檔案前綴 File Prefix"
                            type="text"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={state.filePrefix}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item container direction="column" spacing={1}>
                        <Grid item>
                            <FormLabel component="legend">自定詞表 Custom Word List</FormLabel>
                        </Grid>
                        <Grid item>
                            <TextField
                                id="customWordlist"
                                name='customWordlist'
                                variant='outlined'
                                helperText='觀塘{{Gun1-tong4}}[[Kwun Tong]]'
                                type="text"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                multiline
                                fullWidth
                                rows={5}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item>
                            <Button variant="contained" color="secondary" size="small" onClick={props.updateWordlist}>匯入 Import</Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Typography>
        </FormControl>
    );
};

const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        width: '100%',
    },
    button: {
        marginRight: theme.spacing(1),
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    titles: {
        textAlign: 'center',
    },
    tablabels: {
        textAlign: 'center',
    },
}));

const Converter: React.PureComponent<PanelProps> = (props) => {
    const classes = useStyles();
    const [activeStep, setActiveStep] = React.useState(0);
    const steps = getSteps();

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    function getSteps() {
        return ['輸入文字 Enter Text', '檢查拼音翻譯 Check Pronunciations and Translations'];
    }

    function getStepContent(stepIndex: number) {
        switch (stepIndex) {
        case 0:
            return (
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={12} md={6}>
                        <Panel1
                            text={hanText}
                            onTextChange={handleHanChange}
                            onRequestGenerate={generate} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                        <ConfigPanel
                            onConfigChange={handleConfigChange}
                            updateWordlist={updateWordlistExt}
                            config={config} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                        {(derived)
                && <Button variant="contained" color="primary" size="small" onClick={handleNext}>下一頁 Next</Button>
                        }
                    </Grid>
                </Grid>
            );
        case 1:
            return (
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={12} md={12}>
                        <StripPanels
                            onUpdateMessage={() => {}}
                            wordTable={wordTable}
                            config={config}
                        />
                    </Grid>
                </Grid>
            );
        default:
            return 'Unknown stepIndex';
        }
    }

    const defaultText = '今日你想去邊度玩啊？';
    const cantoDict = cantoneseData;
    const SUPPORT_LANG = ['eng', 'cmn', 'urd', 'nep', 'hin', 'ind'];

    const [hanText, setHanText] = useState(defaultText);
    const [derived, setDerived] = useState(false);
    const [wordTable, setWordTable] = useState([]);
    const [config, setConfig] = useState({
        isManual: false,
        filePrefix: 'panel',
        fontSize: 72,
        honziHeightRatio: 1,
        langOrder: 'eng',
        customWordlist: '',
        useJyutping: true,
        system: 'jyutping',
        mode: 'dancing',
        dancing: true,
        wrapA4: true,
        isBigFont: false,
        showLineNum: false,
        showSegLine: false,
        showLineBreak: false,
        fontType: 'Normal',
        wrapType: 'A4',
        wrapSize: 15,
        lineHeightMultiplier: 1,
        eng: true,
        cmn: false,
        urd: false,
        nep: false,
        hin: false,
        ind: false,
        directives: [],
        watermark: false,
    });

    const handleHanChange = (text:string) => { setHanText(text); };

    const WRAP_SIZE = { A4: 15, A5: 10, NoWrap: 9999 };

    const handleConfigChange = (config:Object) => {
        config.system = (config.useJyutping ? 'jyutping' : 'yale');
        config.wrapType = (config.isManual ? 'NoWrap' : (config.wrapA4 ? 'A4' : 'A5'));
        config.fontType = (config.isBigFont ? 'Big' : 'Normal');
        config.wrapSize = (config.isManual ? config.wrapSize : WRAP_SIZE[config.wrapType]);
        config.mode = (config.dancing ? 'dancing' : 'calm');

        const langSelected = config.langOrder.split(',').filter((lang) => config[lang]);
        SUPPORT_LANG.forEach((lang) => {
            if (config[lang] && !langSelected.includes(lang)) langSelected.push(lang);
        });
        config.langOrder = langSelected.join(',');
        setConfig(config);
    // updateWordlist(config);  //This should only be triggered manually.
    };

    const updateWordlistExt = () => {
        updateWordlist(config);
    };

    const updateWordlist = (config:Object) => {
    // This function handles the custom word list

        config.customWordlist.split('\n').forEach((line, i) => {
            const [fullMatch, directive, char, jp, trans] = line.match(/(\$[^$]*\$ ?)?([^{}[\]]*)({{[^{}[\]]*}})?(\[\[[^{}[\]]*\]\])?/);
            if (!fullMatch || !jp || !char || !trans) {
                console.log(fullMatch, directive, char, jp, trans);
            } else {
                const wordObj = { char, jp: jp.replace(/[{}]/g, ''), trans: trans.replace(/[[\]]/g, '') };
                cantoDict[char] = cantoDict[char] || [];
                cantoDict[char].splice(0, 0, wordObj);
            }
        });
        refreshTrie();
    };

    const generate = (isStaying:boolean = false) => {
        console.log('generate');
        setWordTable([]);
        const updatedDirectives = [].concat(config.directives);
        const words = [];
        let lines = hanText.split('\n');
        lines = lines.map((line) => line.trim());

        for (let i = 0; i < lines.length; ++i) {
            let [fullMatch, directive = '{}', lineName = '', line, lineJpUser, lineTranUser] = lines[i].match(/(\$[^$]*\$ ?)?(\[[^[\]]*\] ?)?([^{}[\]]*)({{[^{}[\]]*}})?(\[\[[^{}[\]]*\]\])?/);
            let lineJp = '';
            let lineJpArr = [];
            let lineTran = '';
            let lineTranArr = [];
            let lineArr = [];
            let lineTranArr2 = null;

            if (!fullMatch) {
                // Error
            }

            while (updatedDirectives.length <= i) updatedDirectives.push({});
            updatedDirectives[i] = JSON.parse(directive.replace(/\$/g, ''));
            updatedDirectives[i].name = lineName.replace(/[[\]]/g, '');

            if (line.match(/^<<.*>>$/)) {
                // New code
                const wordArr = line.replace(/^<<|>>$/g, '').split('>><<');
                lineArr = wordArr.map((word) => word.split('|')[0]);
                lineJpArr = wordArr.map((word) => word.split('|')[1]);
                lineTranArr2 = wordArr.map((word) => word.split('|')[3]);
            } else {
                lineJpUser = markSyllableBoundaries(lineJpUser, config.system);

                const countSyllable = (word) => word.split('-').length;
                const cumulativeSum = ((sum) => (value) => sum += value)(0);

                if (lineJpUser) {
                    const lineArrTemp = decodeUTF8(encodeUTF8(line.replace(/ /g, '')));

                    lineJp = lineJpUser.replace(/^{{/, '').replace(/}}$/, '');
                    lineJpArr = lineJp.split(' ');
                    // If user has provided Jyutping, then use the provided Jyutping to
                    // adjust segmentation. Override existing spacing
                    let adjust = 0;
                    let prevCum = 0;
                    lineJpArr.map((jp) => cumulativeSum(countSyllable(jp)))
                        .forEach((charPerWord, i) => {
                            if (lineArrTemp[i + prevCum] === '(') {
                                adjust += 2;
                            }
                            prevCum = charPerWord + adjust;
                            lineArrTemp.splice(i + prevCum, 0, ' ');
                        });
                    line = lineArrTemp.join('');
                }

                if (lineTranUser) {
                    lineTran = lineTranUser.replace(/^\[\[/, '').replace(/\]\]/, '');
                    lineTranArr = lineTran.split('|');
                }
                lineArr = line.split(' ');
            }

            /// NEW LOGIC
            /// Each entry in the `words` array represents words from one line
            /// called `wordComposition`. Each `wordComposition` contains
            /// yet another array of `word` entries
            /// Each `word` entry has keys such as headword, jp, eng, and other trans.

            const wordComposition = [];

            // 0526: word[0] will always hold the preferred index of the right element.
            for (let j = 0; j < lineArr.length; ++j) {
                let word = [].concat(cantoDict[lineArr[j]] || [{ char: lineArr[j], jp: '#'.repeat(countChar(lineArr[j])).split('').join('-') }]);
                let customItemCount = 0;
                word = JSON.parse(JSON.stringify(word));

                for (let k = 0; k < word.length; ++k) {
                    // Split entries with multiple pronunciations
                    if (word[k].jp.includes('[')) {
                        const allProns = word[k].jp.replace(']', '').split('[');
                        for (let l = 1; l < allProns.length; ++l) {
                            const entry = JSON.parse(JSON.stringify(word[k]));
                            entry.jp = allProns[l];
                            word.push(entry);
                        }
                        word[k].jp = word[k].jp.replace(/\[.*$/, '');
                    }
                    // console.log(word[k].jp);
                    // Re-segment unknown words
                    if (word[k].jp.charAt(0) === '#' && word[k].jp.length > 1 ) {
                        let resegmented = segment(word[k].char).trim().split(' ')
                        // console.log(resegmented);
                        let resegmentedJp = resegmented.map(char => cantoDict[char]? cantoDict[char][0].jp: '#');
                        // console.log(resegmentedJp);
                        word[k].jp = resegmentedJp.join('-').replaceAll(/\[.*\]/g, '');
                    }
                }

                if (lineJpArr.length > j) {
                    word = word.filter((word) => word.jp === lineJpArr[j]);
                    if (word.length === 0) {
                        word.push({ char: lineArr[j], jp: lineJpArr[j] });
                        customItemCount++;
                    }
                }

                if (lineTranArr2) {
                    word.splice(0, 0, { char: lineArr[j], jp: lineJpArr[j], trans: lineTranArr2[j] });
                } else if (lineTranArr.length > 0) {
                    if (customItemCount === 0) {
                        word.splice(0, 0, { char: lineArr[j], jp: lineJpArr[j] });
                        customItemCount++;
                    }

                    // Only replace content of .trans if it has not been defined.
                    if (!word[0].trans) {
                        word[0].trans = '';
                        for (let c = 0; c < countChar(lineArr[j]); ++c) {
                            word[0].trans += lineTranArr.shift();
                        }
                    }
                }
                word.splice(0, 0, 1); // insert 1 as word[0]
                wordComposition.push(word);
            }
            words.push(wordComposition);
        }
        setConfig({ ...config, directives: updatedDirectives });
        setWordTable((current) => [].concat(words));
        setDerived(true);
        if (!isStaying) handleNext();
    };

    return (
        <div className={classes.root}>
            <Typography component={'span'} className={classes.titles}><h2>跳跳紮拼音轉換器 Graphical Cantonese Generator <small>(v.{process.env.REACT_APP_VERSION})</small></h2></Typography>
            <Grid container>
                {activeStep > 0
        && <Grid item xs={2} container alignItems='baseline'>
            <Button variant="contained" size="small" color="default" onClick={handleBack}>返回 Back</Button>
        </Grid>
                }
                <Grid item xs={10}>
                    <Stepper activeStep={activeStep}>
                        {steps.map((label, index) => {
                            const stepProps: { completed?: boolean } = {};
                            const labelProps: { optional?: React.ReactNode } = {};
                            return (
                                <Step key={label} {...stepProps}>
                                    <StepLabel {...labelProps}>{label}</StepLabel>
                                </Step>
                            );
                        })}
                    </Stepper>
                </Grid>
            </Grid>
            <Box m={3}>{getStepContent(activeStep)}</Box>
            <div id='parentDiv'/>
            <span id='fontPreload1'>.</span><span id='fontPreload2'>.</span><span id='fontPreload3'>.</span>
        </div>
    );
};

export default Converter;
