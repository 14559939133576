import decomposeJyutping from './decomposeJyutping';
import decomposeYale from './decomposeYale';

const decomposeSyllable = (syllable: string, system: string): Record<string, unknown> | null => {
    if (system === 'jyutping') {
        return decomposeJyutping(syllable);
    }

    if (system === 'yale') {
        return decomposeYale(syllable);
    }

    return null;
};

export default decomposeSyllable;
