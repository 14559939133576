/* eslint-disable no-multi-spaces */
export const openBracketsRegex = '\\(|{|\\[|<';
export const closeBracketsRegex = '\\)|{|\\]|>';

export const stickLeft = ['。', '，', '﹐', '；', '：', '︰', '！', '？', '」', '』', '〉', '》', '～', '…', '⋯', '、', '—', '─'];
export const stickRight = ['「', '『', '〈', '《'];

/* eslint-disable no-multi-spaces, max-len */
export const jyutpingInitials = [
    'b',  'p',  'm',  'f',
    'd',  't',  'n',  'l',
    'g',  'k',  'ng', 'h',
    'gw', 'kw', 'w',
    'z',  'c',  's',  'j', '',
];

export const jyutpingFinals = [
    'aa', 'aai', 'aau', 'aam', 'aan', 'aang', 'aap', 'aat', 'aak',
    'a',  'ai',  'au',  'am',  'an',  'ang',  'ap',  'at',  'ak',
    'e',  'ei',  'eu',  'em',  'en',  'eng',  'ep',  'et',  'ek',
    'i',         'iu',  'im',  'in',  'ing',  'ip',  'it',  'ik',
    'o',  'oi',  'ou',         'on',  'ong',  'op',  'ot',  'ok',
    'u',  'ui',         'um',  'un',  'ung',  'up',  'ut',  'uk',
    'oe',                      'oen', 'oeng',        'oet', 'oek',
    'eoi',                     'eon',                      'eot',
    'yu',                      'yun',                'yut',
    'm', 'ng',
];

export const yaleInitials = [
    'b',  'p',  'm',  'f',
    'd',  't',  'n',  'l',
    'g',  'k',  'ng', 'h',
    'gw', 'kw', 'w',
    'j',  'ch',  's', 'y', '',
];

export const yaleFinals = [
    '[aāáà]a?h?', '[aāáà]a?ih?', '[aāáà]a?uh?', '[aāáà]a?h?m', '[aāáà]a?h?n', '[aāáà]a?h?ng', '[aāáà]a?h?p', '[aāáà]a?h?t', '[aāáà]a?h?k',
    // 'ah?',  'aih?',  'auh?',  'ah?m',  'ah?n',  'ah?ng',  'ah?p',  'ah?t',  'ah?k',
    '[eēéè]h?',  '[eēéè]ih?',           '[eēéè]h?m',  '[eēéè]h?n',  '[eēéè]h?ng',  '[eēéè]h?p',  '[eēéè]h?t',  '[eēéè]h?k',
    '[iīíì]h?',           '[iīíì]uh?',  '[iīíì]h?m',  '[iīíì]h?n',  '[iīíì]h?ng',  '[iīíì]h?p',  '[iīíì]h?t',  '[iīíì]h?k',
    '[oōóò]h?',  '[oōóò]ih?',  '[oōóò]uh?',           '[oōóò]h?h?n', '[oōóò]h?ng',  '[oōóò]h?p',  '[oōóò]h?t',  '[oōóò]h?k',
    '[uūúù]h?',  '[uūúù]ih?',           '[uūúù]h?m',  '[uūúù]h?n',  '[uūúù]h?ng',  '[uūúù]h?p',  '[uūúù]h?t',  '[uūúù]h?k',
    '[eēéè]uh?',                            '[eēéè]uh?ng',                            '[eēéè]uh?k',
    '[eēéè]uih?',                   '[eēéè]uh?n',                '[eēéè]uh?t',
    'y[uūúù]h?',                            'y[uūúù]h?n',                'y[uūúù]h?t',
    'm[\u0300\u0301\u0304]?h?',  'n[\u0300\u0301\u0304]?gh?',
];

export enum PhoneticType {
    Jyutping = 'jyutping',
    Yale = 'yale',
    Invalid = 'invalid'
}

export const jp2yale = [
    { from: 'J', to: 'Y' },
    { from: 'j', to: 'y' },
    { from: 'Z', to: 'J' },
    { from: 'z', to: 'j' },
    { from: 'C', to: 'Ch' },
    { from: 'c', to: 'ch' },
    { from: 'Yy', to: 'Y' },
    { from: 'yy', to: 'y' },
    { from: 'Oe', to: 'Eu' },
    { from: 'oe', to: 'eu' },
    { from: 'Eo', to: 'Eu' },
    { from: 'eo', to: 'eu' },
    { from: '([456])', to: '$1h' },
    { from: '(p|t|k|m|ng|n|M|Ng)(1|2|3|[456]h)', to: '$2$1' },
    { from: '([AEIOUaeiou])([aeiou])([1-6])', to: '$1$3$2' },
    { from: '([AEIOUaeiou])([aeiou])([1-6])', to: '$1$3$2' },
    { from: '([AEIOUaeiou])[36]', to: '$1' },
    { from: '([AEIOUaeiou])[25]', to: '$1\u0301' },
    { from: '([AEIOUaeiou])1', to: '$1\u0304' },
    { from: '([AEIOUaeiou])4', to: '$1\u0300' },
    { from: '1(m|ng|M|Ng)', to: '$1\u0304' },
    { from: '[25](h?)(m|ng|M|Ng)', to: '$2\u0301$1' },
    { from: '4(h)(m|ng|M|Ng)', to: '$2\u0300$1' },
    { from: '[36](h)(m|ng|M|Ng)', to: '$2$1' },
    { from: 'a(?=[ -.!?,;~:\n])', to: '' },
];

export const yale2jp = [
    { from: '[aáàā](?=[ -.!?,;~:\n])', to: 'aa' },
    { from: '([A-Za-z])(?=[ -.!?,;~:\n])', to: '$1\u0033' },
    { from: '([\u0300\u0301\u0304])([aeiou]{1,2})', to: '$2$1' },
    { from: '([\u0300\u0301\u0304]h?)(p|t|k|m|ng|n)', to: '$2$1' }, // 3 is the default tone
    { from: 'h(p|t|k|m|ng|n)', to: '$1h' }, // 3 is the default tone
    { from: '\u0300h3', to: '4' },
    { from: '\u0301h3', to: '5' },
    { from: 'h3', to: '6' },
    { from: 'h(?=[ -.!?,;~:\n])', to: '6' },
    { from: '\u03013', to: '2' },
    { from: '\u0301', to: '2' },
    { from: '\u03043', to: '1' },
    { from: '\u0304', to: '1' },
    { from: 'Eu', to: 'Oe' },
    { from: 'eu', to: 'oe' },
    { from: 'Oe(?=[tin][^g])', to: 'Eo' },
    { from: 'oe(?=[tin][^g])', to: 'eo' },
    { from: '([Cc])h', to: '$1' },
    { from: 'J', to: 'Z' },
    { from: 'j', to: 'z' },
    { from: 'y(?=[^u])', to: 'j' },
    { from: 'Y(?=[^u])', to: 'J' },
    { from: 'Yu', to: 'Jyu' },
    { from: '^yu', to: 'jyu' },
    { from: '([ -.!?,;~:\n])yu', to: '$1jyu' },
];
