import { yaleFinals, yaleInitials } from '../constants';

// TODO Don't allow decomposing more than one syllable?
const decomposeYale = (syllables: string): Record<string, unknown> => {
    const allSylls = syllables.split(';');
    const syllable = allSylls[0];
    const yale = `^(${yaleInitials.join('|')})(${yaleFinals.join('|')})$`;
    const syllable_n = syllable.normalize('NFD');
    const matchYale = (new RegExp(yale, 'i')).exec(syllable_n.replace(/[\u0300-\u036f]/g, ''));

    if (matchYale) {
        return {
            type: 'yale',
            onset: matchYale[1],
            rhyme: syllable.substr(matchYale[1].length, syllable.length - matchYale[1].length),
            tone: (matchYale[2].includes('h')
                ? (/* T4-T6 */ syllable_n.includes('\u0300')
                    ? '4'
                    : syllable_n.includes('\u0301') ? '5' : '6'
                )
                : (/* T1-T3 */ syllable_n.includes('\u0300') || syllable_n.includes('\u0304')
                    ? '1'
                    : syllable_n.includes('\u0301') ? '2' : '3'
                )
            ),
            syll: syllable,
            notone: matchYale[1] + matchYale[2],
        };
    }

    return {
        onset: '', rhyme: syllable, tone: 0, syll: syllable, notone: syllable, type: 'invalid',
    };
};

export default decomposeYale;
