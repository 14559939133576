import {
    jyutpingFinals, jyutpingInitials, yaleFinals, yaleInitials,
} from '../constants';

const markSyllableBoundaries = (message: string, system: string): string => {
    if (!message) return '';

    const jyutping = `(${jyutpingInitials.join('|')})(${jyutpingFinals.join('|')})([1-6])`;
    const yale = `(${yaleInitials.join('|')})(${yaleFinals.join('|')})`;

    if (system === 'jyutping') {
        message = message.replace(new RegExp(`(${jyutping})(?=${jyutping})`, 'gi'), '$1-');
    }

    if (system === 'yale') {
        const lines = message.split('\n');
        for (const line in lines) {
            const mArray = lines[line].replace(/(?=[.!?,;~:])/g, ' ').split(' ');
            for (let i = 0; i < mArray.length; i++) {
                if (!mArray[i].match(`^${yale}$`)) {
                    mArray[i] = mArray[i].replace(new RegExp(`(${yale})(?=${yale})`, 'gi'), '$1#-');
                }
                const dashArray = mArray[i].split('-');
                for (let i = 0; i < dashArray.length; ++i) {
                    if (i + 1 < dashArray.length && dashArray[i][dashArray[i].length - 1] === '#') {
                        dashArray[i] = dashArray[i].substr(0, dashArray[i].length - 1);
                        if ((dashArray[i] + dashArray[i + 1]).match(new RegExp(`^${yale}#?$`, 'gi'))) {
                            dashArray[i + 1] = dashArray[i] + dashArray[i + 1];
                            dashArray[i] = '';
                        }
                    }
                }
                mArray[i] = dashArray.filter((val) => val).join('-');
            }
            lines[line] = mArray.join(' ').replace(/ (?=[.!?,;~:])/g, '');
        }
        message = lines.join('\n');
    }

    return message;
};

export default markSyllableBoundaries;
