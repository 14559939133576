// @ts-nocheck

import React from 'react';
import TextImage from './TextImage';
import { encodeUTF8, decodeUTF8 } from './helpers/utf8';
import markSyllableBoundaries from './helpers/markSyllableBoundaries';

const TextImageWrapper : React.PureComponent = (props) => {
    const { message } = props;
    if (!message) return null;

    const lines = message.split('\n');
    for (let i = 0; i < lines.length; ++i) {
        if (i % 3 === 1) {
            lines[i] = markSyllableBoundaries(lines[i], props.style.system);
        }
    }
    props.onUpdateMessage(lines.join('\n'));

    const strips = [];
    for (let j = 0; j < lines.length; ++j) {
        let charLine = ''; let
            glossLine = '';
        if (props.style.withHonzi && props.style.withGloss) {
            if (j % 3 !== 1) continue;
            charLine = decodeUTF8(encodeUTF8(lines[j - 1]));
            glossLine = (lines[j + 1] || '').split('|');
        } else if (props.style.withHonzi) {
            if (j % 2 === 0) continue;
            charLine = decodeUTF8(encodeUTF8(lines[j - 1]));
        } else if (props.style.withGloss) {
            if (j % 2 === 1) continue;
            glossLine = (lines[j + 1] || '').split('|');
        }
        let words = lines[j].replace(/-/g, '- ')
            .replace(/(?=[.!?,;~:])/g, ' ')
            .replace(/(?=\. \.)/g, '..')
            .split(' ');
        words = words.map((word) => ({ word }));
        let charOffset = 0;
        for (let k = 0; k < words.length; ++k) {
            let k2 = k + charOffset;
            words[k].directive = '';

            if ((charLine[k2] || '').match(/[)}>\]]/)) {
                if (k > 0) words[k - 1].directive += charLine[k2];
                ++charOffset; ++k2;
            }
            if ((charLine[k2] || '').match(/[({<[]/)) {
                words[k].directive += charLine[k2];
                ++charOffset; ++k2;
            }
            words[k].char = charLine[k2] || '';
            words[k].gloss = glossLine[k] || '';
        }
        strips.push(words);
    }

    return (
        <div>
            <TextImage
                id={props.id}
                name={props.name}
                style={props.style}
                words={props.words}
                config={props.config}
            />
        </div>
    );
};

export default TextImageWrapper;
